
import { defineComponent, ref, onMounted, computed } from "vue";
import { ApiBase, ApiFulfillmentOrders } from "@/core/api";
import { useI18n } from "vue-i18n";
import {
  TaggingChildrenItem,
  TaggingItem,
} from "@/core/directive/interface/common";

interface Filter {
  category: string;
  fulfillment_type: string;
  cargo_damage_level: string;
}

export default defineComponent({
  name: "filter-sales-invoice-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      category: "",
      fulfillment_type: "",
      cargo_damage_level: "",
    });

    const options = ref({
      category: [] as TaggingItem[],
      fulfillment_type: [] as TaggingChildrenItem[],
      cargo_damage_level: [] as TaggingItem[],
      alarm_mode: [] as TaggingItem[],
    });

    const handleCategory = () => {
      formData.value.cargo_damage_level = "";
    };

    const getCargoDamageLevel = computed(() => {
      let cargoDamageLevel: TaggingItem[] = [];
      if (formData.value.category !== "") {
        options.value.cargo_damage_level.map((item: TaggingItem) => {
          if (formData.value.category === "1" && item.value === "10") {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "2" && item.value === "20") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "3" &&
            (item.value === "40" || item.value === "30")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "4" &&
            (item.value === "50" || item.value === "60" || item.value === "70")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "5" &&
            (item.value === "80" || item.value === "90" || item.value === "100")
          ) {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "6" && item.value === "110") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "7" &&
            (item.value === "120" || item.value === "130")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "8" &&
            (item.value === "140" ||
              item.value === "150" ||
              item.value === "160")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "9" &&
            (item.value === "170" ||
              item.value === "180" ||
              item.value === "190" ||
              item.value === "200" ||
              item.value === "210")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "10" &&
            (item.value === "220" || item.value === "230")
          ) {
            cargoDamageLevel.push(item);
          }
        });
      }
      return cargoDamageLevel;
    });

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      console.log(formData.value);
      emit("reset-filter");
    };

    const getOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "monitoring_rules_category",
          "monitoring_rules_cargo_damage_level",
          "monitoring_rules_alarm_method",
        ],
      });
      if (data.code == 0) {
        options.value.category = data.data.monitoring_rules_category.items;
        options.value.cargo_damage_level =
          data.data.monitoring_rules_cargo_damage_level.items;
        options.value.alarm_mode =
          data.data.monitoring_rules_alarm_method.items;
      }
    };

    const getFulfillmentModeSource = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentModeSource({
        order_id: 0,
      });
      if (data.code == 0) {
        options.value.fulfillment_type = data.data;
      }
    };

    onMounted(() => {
      getOptions();
      getFulfillmentModeSource();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
      handleCategory,
      getCargoDamageLevel,
    };
  },
});
