
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdown from "@/views/risk-and-exception/monitoring-rules/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiWarningRules } from "@/core/api";
import addWarningRulesModal from "@/views/risk-and-exception/monitoring-rules/AddWarningRulesModal.vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { WarningRuleTable } from "@/core/directive/interface/warningRule";

import store from "@/store";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "risk-and-exception-monitoring-rules",
  components: {
    MBDatatable,
    FilterDropdown,
    addWarningRulesModal,
    // addExceptionHandlingsModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<WarningRuleTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);

    const tableHeader = ref([
      {
        name: t("warningRules.category"),
        key: "category",
        sortable: true,
      },
      {
        name: t("warningRules.fulfillmentType"),
        key: "fulfillment_type",
        sortable: false,
      },
      {
        name: t("warningRules.maximumExtensionDays"),
        key: "maximum_extension_days",
        sortable: false,
      },
      {
        name: t("warningRules.levelOptions"),
        key: "cargo_damage_level",
        sortable: false,
      },
      {
        name: t("warningRules.liquidatedDamages"),
        key: "liquidated_damages",
        sortable: false,
      },
      {
        name: t("warningRules.processingMethod"),
        key: "processing_method",
        sortable: false,
      },
      {
        name: t("warningRules.referenceScheme"),
        key: "reference_scheme",
        sortable: false,
      },
      {
        name: t("warningRules.alarmMode"),
        key: "alarm_mode",
        sortable: false,
      },
      {
        name: t("warningRules.alarmContent"),
        key: "alarm_content",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getWarningRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    // const getLabel = (val, key) => {
    //   return options.value[key].find((item) => {
    //     return item.value == val;
    //   })?.label;
    // };

    const getWarningRulesList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiWarningRules.getMonitoringRulesList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getWarningRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getWarningRulesList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getWarningRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          filterArr.push({
            field: item,
            value: val[item],
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getWarningRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getWarningRulesList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getWarningRulesList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case "New":
          statusClass = "badge-light-primary";
          break;
        case "Processing":
          statusClass = "badge-light-warning";
          break;
        case "Partial Shipped":
        case "Shipped":
          statusClass = "badge-light-info";
          break;
        case "Complete":
          statusClass = "badge-light-success";
          break;
        case "Refunded":
          statusClass = "badge-light-danger";
          break;
        case "Canceled":
          statusClass = "badge-light-dark";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    // const getOptions = async () => {
    //   ApiBase.getTaggingData({
    //     short_key: [
    //       "monitoring_rules_category",
    //       "mb_fulfillment_type",
    //       "monitoring_rules_cargo_damage_level",
    //       "monitoring_rules_alarm_method",
    //     ],
    //   })
    //     .then(({ data }) => {
    //       if (data.code == 0) {
    //         options.value.category = data.data.monitoring_rules_category.items;
    //         options.value.fulfillment_type =
    //           data.data.mb_fulfillment_type.items;
    //         options.value.cargo_damage_level =
    //           data.data.monitoring_rules_cargo_damage_level.items;
    //         options.value.alarm_mode =
    //           data.data.monitoring_rules_alarm_method.items;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      getWarningRulesList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      // getLabel,
      // options,
    };
  },
});
