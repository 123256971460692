
import { defineComponent, ref, onMounted, computed } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiFulfillmentOrders, ApiWarningRules } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import Editor from "@tinymce/tinymce-vue";
import {
  TaggingChildrenItem,
  TaggingItem,
} from "@/core/directive/interface/common";

export default defineComponent({
  name: "add-exception-handlings-modal",
  components: { editor: Editor },
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const addStoresModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      category: "",
      fulfillment_type: "",
      maximum_extension_days: "",
      cargo_damage_level: "",
      liquidated_damages: "",
      processing_method: "",
      reference_scheme: "",
      alarm_mode: [],
      alarm_content: "",
    });

    const options = ref({
      category: [] as TaggingItem[],
      fulfillment_type: [] as TaggingChildrenItem[],
      cargo_damage_level: [] as TaggingItem[],
      alarm_mode: [] as TaggingItem[],
    });

    const handleCategory = () => {
      formData.value.cargo_damage_level = "";
    };

    const getCargoDamageLevel = computed(() => {
      let cargoDamageLevel: TaggingItem[] = [];
      if (formData.value.category !== "") {
        options.value.cargo_damage_level.map((item: TaggingItem) => {
          if (formData.value.category === "1" && item.value === "10") {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "2" && item.value === "20") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "3" &&
            (item.value === "40" || item.value === "30")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "4" &&
            (item.value === "50" || item.value === "60" || item.value === "70")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "5" &&
            (item.value === "80" || item.value === "90" || item.value === "100")
          ) {
            cargoDamageLevel.push(item);
          } else if (formData.value.category === "6" && item.value === "110") {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "7" &&
            (item.value === "120" || item.value === "130")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "8" &&
            (item.value === "140" ||
              item.value === "150" ||
              item.value === "160")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "9" &&
            (item.value === "170" ||
              item.value === "180" ||
              item.value === "190" ||
              item.value === "200" ||
              item.value === "210")
          ) {
            cargoDamageLevel.push(item);
          } else if (
            formData.value.category === "10" &&
            (item.value === "220" || item.value === "230")
          ) {
            cargoDamageLevel.push(item);
          }
        });
      }
      return cargoDamageLevel;
    });

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === "" && formRef.value?.model.category == 1) {
        callback(new Error(t("common.isRequired")));
      } else {
        callback();
      }
    };

    const validatePass2 = (rule: any, value: any, callback: any) => {
      //  && formRef.value?.model.category == 2
      if (value === "") {
        callback(new Error(t("common.isRequired")));
      } else {
        callback();
      }
    };

    const rules = ref({
      category: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      alarm_mode: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      maximum_extension_days: [
        {
          validator: validatePass,
          trigger: "change",
        },
      ],
      cargo_damage_level: [
        {
          validator: validatePass2,
          trigger: "change",
        },
      ],
    });

    const handleDiscard = () => {
      hideModal(addStoresModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiWarningRules.addMonitoringRules(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addStoresModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getOptions = async () => {
      ApiBase.getTaggingData({
        short_key: [
          "monitoring_rules_category",
          "monitoring_rules_cargo_damage_level",
          "monitoring_rules_alarm_method",
        ],
      })
        .then(({ data }) => {
          if (data.code == 0) {
            options.value.category = data.data.monitoring_rules_category.items;
            options.value.cargo_damage_level =
              data.data.monitoring_rules_cargo_damage_level.items;
            options.value.alarm_mode =
              data.data.monitoring_rules_alarm_method.items;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFulfillmentModeSource = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentModeSource({
        order_id: 0,
      });
      if (data.code == 0) {
        options.value.fulfillment_type = data.data;
      }
    };

    onMounted(() => {
      getOptions();
      getFulfillmentModeSource();
    });

    return {
      t,
      loading,
      formData,
      addStoresModalRef,
      submitButton,
      rules,
      formRef,
      options,
      validatePass,
      validatePass2,
      submit,
      handleDiscard,
      resetForm,
      getOptions,
      handleCategory,
      getCargoDamageLevel,
    };
  },
});
